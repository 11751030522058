import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "",
  },
  {
    id: "logout",
    title: "Logout",
    type: "item",
    icon: <Icon.LogOut size={20} />,
    permissions: ["admin", "editor"],
    navLink: "logout"
  }
]

export default horizontalMenuConfig
