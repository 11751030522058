import React from "react"
import * as Icon from "react-feather"
import { store } from "../redux/storeConfig/store"
import { logoutUserAction } from '../redux/actions/auth/loginActions'

const apkVersion = '0-0-7';

const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "checker"],
    navLink: "/admin"
  },
  {
    id: "assessments",
    title: "Passages",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["admin"],
    navLink: "/admin/passages"
  },
  {
    id: "schools",
    title: "Schools",
    type: "item",
    icon: <Icon.Database size={20} />,
    permissions: ["admin"],
    navLink: "/admin/schools"
  },
  {
    id: "grades",
    title: "Grades",
    type: "item",
    icon: <Icon.TrendingUp size={20} />,
    permissions: ["admin"],
    navLink: "/admin/grades"
  },
  {
    id: "classes",
    title: "Classes",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: ["admin"],
    navLink: "/admin/classes"
  },
  {
    id: "students",
    title: "Students",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/admin/students"
  },
  {
    id: "prospects",
    title: "Prospects",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin"],
    navLink: "/admin/prospects"
  },
  {
    id: "exams",
    title: "Exams",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["admin", "checker"],
    navLink: "/admin/exams"
  },
  {
    id: "notifications",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={20} />,
    permissions: ["admin"],
    navLink: "/admin/notifications"
  },
  {
    id: "createbycsv",
    title: "Csv upload",
    type: "item",
    icon: <Icon.FilePlus size={20} />,
    permissions: ["admin"],
    navLink: "/admin/create-by-csv"
  },
  {
    id: "books",
    title: "Books",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin"],
    navLink: "/admin/all-books"
  },
  {
    id: "roles",
    title: "Roles",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin"],
    navLink: "/admin/roles"
  },
  {
    id: "testing",
    title: "Test cases",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["admin"],
    navLink: "/admin/testing"
  },
  {
    id: "apk",
    title: `Last .apk (${apkVersion})`,
    type: "external-link",
    icon: <Icon.DownloadCloud size={20} />,
    permissions: ["admin", "checker"],
    navLink: `http://teeread.com/apk_builds/${apkVersion}.apk`
  },
  {
    id: "logout",
    title: "Logout",
    type: "item",
    icon: <Icon.Power size={20} />,
    permissions: ["admin", "checker"],
    navLink: "/admin/login",
    action: () => {
      store.dispatch(logoutUserAction());
      localStorage.removeItem('user-tr');
    }
  }
]

export default navigationConfig
