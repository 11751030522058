const user = JSON.parse(localStorage.getItem('user-tr'));
//cada reducer tiene su propio state
const initialState = {
  user: user ? user.user : {},
  token: user ? user.token : '',
  userRole: user ? user.user.role.name : '',
}

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, user: action.payload.user, token: action.payload.token, userRole: action.payload.userRole }
    }
    case "LOGOUT": {
      return { ...state, user: {}, token: '', userRole: '' }
    }
    default: {
      return state
    }
  }
}
