export const loginUserAction = user => {
  return dispatch => dispatch({
    type: "LOGIN",
    payload: { user: user.user, token: user.token, userRole: user.user.role.name }
  })
}
export const logoutUserAction = () => {
  return dispatch => dispatch({
    type: "LOGOUT",
  })
}
